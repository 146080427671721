@font-face {
    font-family: MyriadPro;
    /*src: url("/assets/lib/MyriadPro-Regular.otf") format("opentype"), url("../fonts/MyriadProRegular.ttf") format("truetype");*/
    src: url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/MyriadPro-Regular.otf')  format('opentype'),
	     url('../fonts/MyriadPro-Regular.woff') format('woff'), url('../fonts/MyriadPro-Regular.ttf')  format('truetype'), url('../fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
         font-weight: normal;
         font-style: normal;
}

td, th {
    vertical-align: middle;
}

.fade-animation {
	-webkit-transition: opacity .5s ease-in-out;
	transition: opacity .5s ease-in-out;
}

.fade-in {
	opacity: 1;
}

.fade-out {
	opacity: 0;
}

.table-handler {
	position: relative;
}

.table-handler-init-indicator {
	z-index: 100;
}

.headingSelectorContainer { }

@-webkit-keyframes pulse {
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.1); }
    100% { -webkit-transform: scale(1); }
}

@keyframes pulse {
    0% { -webkit-transform: scale(1); transform: scale(1); }
    50% { -webkit-transform: scale(1.1); transform: scale(1.1); }
    100% { -webkit-transform: scale(1); transform: scale(1); }
}

.table-handler-init-indicator > * {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    font-size: 21px;
    display: block;
    padding: 20px;
    font-weight: 400;
    background: transparent;
    text-align: center;
    color: #7b7a9a;
    margin:0 auto;
}

.fullscreen {
    box-sizing:border-box;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background: #fff !important;
	z-index: 110;
	padding:8px;
}

.fullscreen-button-container {
	font-size: 14px;
}

@media(max-width:780px) {
    .datatables-toolbox > .fullscreen-button-container {
        display:none;
    }
}

.fullscreen-button-container > .fullscreen-text {
	color: #808080;
}

.fullscreen-button-container > .fullscreen-button {
	padding:5px;
	font-weight:300;
	outline: 0 none;
	background: transparent;
	font-family:inherit;
	cursor:pointer;
	border: 0 none;
	color:#8a8a8a;
	font-size:16px;
	margin-right:20px;
	line-height: initial;
	
	-webkit-transition: background .55s ease;
	transition: background .55s ease;
}

.fullscreen-button-container > .fullscreen-button:hover {
	background-color: #efefef;
}

@media (max-width:780px) {
    .fullscreen-button-container > .fullscreen-button {
        margin:0 8px;
    }
}

.fullscreen-button-container > .fullscreen-button.fullscreen-enter:after {
    vertical-align: sub;
    display: inline-block;
    content: '';
    background: url(../images/fullscreen-icon.svg) no-repeat 0 0;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    box-sizing: border-box;
}

.fullscreen-button-container > .fullscreen-button.fullscreen-exit:after {
	content: "\e68a";
	font-family: 'icomoon';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-size: 16px;
	vertical-align: middle;
	padding-left: 10px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

body {
	background-color: #fff;
	overflow-y: scroll;
}

body.isInFullscreen {
	overflow-y: hidden;
}

body.isInFullscreen {
    
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

.search-box > .search-box-container {
	width: 100%;
	height: 100%;
	vertical-align: middle;
	white-space: nowrap;
	position: relative;
}

.search-box > .search-box-container > input {
    height: 38px;
    background: url(../images/table-search-icon.svg) no-repeat 10px 8px;
    font-size: 16px;
    padding-left: 38px;
    margin: 0;
    background-size: 18px 18px;
    border: 1px solid #b4b9bd;
    -webkit-transition: background .55s ease;
    transition: background .55s ease;
    font-family: inherit;
    min-width:398px;
    color:#232157;
    
}

@media (max-width:980px) {
    .search-box > .search-box-container > input {  
        min-width:auto;
    }
}

@media (max-width:766px) {
    .search-box > .search-box-container { margin-right:10px; }
    .search-box > .search-box-container > input { min-width:240px; }
}

.search-box > .search-box-container > .icon-search:before {
    display:none;
}

.search-box > .search-box-container > .icon-search {
    
}

.search-box > .search-box-container > input:hover,
.search-box > .search-box-container > input:focus,
.search-box > .search-box-container > input:active {
	outline: none;
}

.search-box > .search-box-container > input::-webkit-input-placeholder {
	color: #65737e;
}

.search-box > .search-box-container > input:-moz-placeholder { /* Firefox 18- */
	color: #65737e;
}

.search-box > .search-box-container > input::-moz-placeholder { /* Firefox 19+ */
	color: #65737e;
}

.search-box > .search-box-container > input:-ms-input-placeholder {
	color: #65737e;
}

.search-box > .search-box-container > input::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.dialog-container {
	position: absolute;
	z-index:1;
	background: #e2e5e8;
	border: 1px solid #f3f3f6;
	border-radius: 0;
	box-shadow:8px 5px 8px rgba(0,0,0,0.15);
}

.dialog-container > .dialog-container-inner .buttons {
	margin-top: 20px;
	color:#232157;
	border-top: 1px solid #d2d6d8;
}

.dialog-container > .dialog-container-inner .buttons > button {
	font-size: 16px;
	line-height:normal;
	vertical-align:middle;
	font-weight: 400;
	border: 0;
	background:url(../images/datadwl-icon.svg) no-repeat 0 70%;
	padding:13px 0 0 30px;
	color:#232157;
	margin:0 0 0 10px;
}

.dialog-container > .dialog-container-inner .buttons > button:hover {
	text-decoration: underline;
}

.dialog-container > .dialog-container-inner .buttons > button.icon-download:before {
    display:none;
}

.intra-day-dialog {
	z-index: 100;
	padding: 10px;
}

/* chart infobar */
.dialog-container .chart-infobar {
    margin:0;
    white-space:nowrap;
    color:#232157;
}

.dialog-container .chart-infobar .chart-title {
    font-size:18px;
    margin:0 0 0 16px;
    display:inline-block;
    
}

/*JQUERY UI reset*/
.ui-widget {
	font-family: inherit;
	font-size: inherit;
}

.ui-tabs.ui-widget-content.ui-widget-content {
	border: none;
	background: transparent;
	color: inherit;
	z-index:1;
	clear:both;
}

.ui-tabs {
	padding: 0;
}

.ui-tabs .ui-tabs-panel {
	padding: 0;
}

.ui-widget-header.ui-widget-header {
	border: none;
	background: transparent;
	color: inherit;
	font-weight: inherit;
}

.ui-widget-overlay {
    position: fixed;
}

table.dataTable td.old-date {
	color:#8a8a8a;
}

table.dataTable {
	margin: 0;
}

.container {
	width: 1240px;
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}

.text-align-right {
	text-align: right;
}

.text-align-left {
	text-align: left;
}

.text-align-center {
	text-align: center;
}

.clickable {
	text-decoration: underline;
	cursor: pointer;
}

.buttons > button:before {
	padding-right: 15px;
}

[data-tab="stockFutures"] .table-handler .datatables-toolbox,
[data-tab="stockOptions"] .table-handler .datatables-toolbox {
	display: none;
}

#promptMarket #equities .table-handler {}

#promptMarket [data-tab="certificates"] .table-handler .datatables-toolbox {
	display: none;
}

#promptMarket [data-tab="certificates"] .datatables-toolbox {
	float: none;
}

#promptMarket #certificates-tab .certifactes-header {
	margin-bottom: 16px;
}

#promptMarket #certificates-tab .certifactes-header .table-title-container {
	float: none;
}

#promptMarket #certificates-tab .certifactes-navbar {
	display: table;
	vertical-align: middle;
}

#promptMarket #certificates-tab .certifactes-navbar > * {
	display: table-cell;
	vertical-align: middle;
}

@media(max-width:766px) {
    #promptMarket #certificates-tab .certifactes-navbar, #promptMarket #certificates-tab .certifactes-navbar > * {
        display:block;
    }

    #promptMarket #certificates-tab .certifactes-navbar > ul.ui-tabs-nav {
        margin:0 0 16px 0;
        display:table;
    }
    
}

#promptMarket #certificates-tab .certifactes-navbar ul li a {
	height: auto;
	text-transform: none;
}

#tabmenu-div > ul.ui-tabs-nav {
	border: 1px solid #7B7A9A;
	border-radius: 0;
	display:table;
	margin:0 0 40px;
	box-sizing:border-box;
	background:none transparent;
}

#tabmenu-div > ul.ui-tabs-nav li {
	background: #fff;
	border: 0 none;
	margin: 0;
	text-transform: uppercase;
	white-space: normal;
	display: table-cell;
	text-align: center;
	vertical-align: top;
	position:relative;
}

#tabmenu-div > .ui-tabs-nav li:before {
    display: block;
    position: absolute;
    content: '';
    width: 1px;
    top: 50%;
    height: 32px;
    background-color: #7b7a9a;
    margin-top: -16px;
    margin-left: -1px;
}

#tabmenu-div > .ui-tabs-nav li:first-child:before {
	display: none;
}

#tabmenu-div > .ui-tabs-nav li.ui-tabs-active {
	background: #232157;
	z-index:1;
}

#tabmenu-div > .ui-tabs-nav li.ui-tabs-active:before {
	display: none;
}

#tabmenu-div > .ui-tabs-nav li a {
	height:auto;
	width: 100%;
	color: #232157;
	padding: 19px 10px 14px;
	display: inline-block;
	text-align: center;
	vertical-align: top;
	box-sizing: border-box;
	font-size:16px;
}

#tabmenu-div > select {
    display:none;
}

@media (max-width:980px) {
    #tabmenu-div > .ui-tabs-nav li a {
        padding: 15px 10px 10px;
        font-size:15px;
    }
}


@media (max-width:900px) {
    #tabmenu-div > .ui-tabs-nav li a {
        padding:15px 8px 10px;
        font-size:13px;
    }
}


#tabmenu-div > .ui-tabs-nav li a:hover {
    text-decoration: underline;
}

#tabmenu-div > .ui-tabs-nav li.ui-tabs-active a {
	color:white;
}

#tabmenu-div > .ui-tabs-nav li.ui-tabs-active a:hover {
	text-decoration: none;
}

#tabmenu-div > .ui-tabs-nav li.ui-tabs-active a:after {
    border-top: 7px solid #232157;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    display: block;
    position: absolute;
    content: '';
    width: 0;
    bottom: -7px;
    left: 50%;
    height: 0;
    margin-left: -9px;
}

.ui-tabs ul.ui-tabs-nav {
	display: table;
	width: 100%;
	padding: 0;
	margin:0;
}

/* Responsive tab menu over tables */
@media(max-width:766px) {
    
    #tabmenu-div {
        position:relative;
        display:block;
        padding: 10px 16px;
        background-color: #232157;
        margin-bottom:36px;
    }
    
    #tabmenu-div:after {
        border-top: 7px solid #232157;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        display: block;
        position: absolute;
        content: '';
        width: 0;
        bottom: -7px;
        left: 50%;
        height: 0;
        margin-left: -9px;
    }
    
    #tabmenu-div > ul.ui-tabs-nav {
        display:none;
    }

    #tabmenu-div > select {
        display: block;
        padding:0 12px;
        height:36px;
        border:0 none;
        width: 100%;
        font-size: 18px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #fff url('../images/arrow-select.svg') no-repeat 98% 50%;
    }
    
    #tabmenu-div > select option {
        padding:8px 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
	float: none;
	padding: 0;
	text-decoration: none;
}

.market-page .dataTables_wrapper.no-footer .dataTables_scrollBody {
	border-bottom: 0;
}

.market-page table.dataTable.no-footer {
	border-bottom: 0;
}
@media (max-width: 766px) {
    .market-page table.dataTable.no-footer {
	    -webkit-overflow-scrolling: touch;
	    overflow-x: auto;
    }
}

.ui-tabs .ui-tabs-nav.ui-tabs-nav li {
	margin: 0;
	border-bottom-width: 1px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	background: #E2E5E8;
	text-transform: uppercase;
	white-space: normal;
	display: table-cell;
	float: none;
	text-align: center;
	vertical-align: top;
	border: none;
	border-left: 1px solid #7B7A9A;
}

.ui-tabs .ui-tabs-nav li:first-child {
	border-left: none;
}

.ui-tabs .ui-tabs-nav li.ui-state-hover:not(.ui-tabs-active) {
	background: #D2D6D8;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
	background: #232157;
}

.ui-tabs .ui-tabs-nav.ui-tabs-nav li a {
	display: block;
	color: #232157;
	padding: 8px 16px 4px 16px;
	float: none;
	outline: 0;
}

@media(max-width:480px) {
    .ui-tabs .ui-tabs-nav.ui-tabs-nav li a {
        padding: 8px 4px 4px 4px;
    }
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active a {
	color: #F3F3F6;
}


/* Datatables */

#hataridos_reszveny_es_indextermekek .table-row-alternate-color .dt-column-order{
    display: none;
}

#hataridos_reszveny_es_indextermekek .table-row-alternate-color > th,
#hataridos_reszveny_es_indextermekek .table-row-alternate-color > td{
    border-bottom: 0 none;
    position: unset;
}

#hataridos_reszveny_es_indextermekek tr.table-row-alternate-color + tr{
    background: #7b7a9a;
}

#hataridos_reszveny_es_indextermekek tr.table-row-alternate-color + tr > th {
    border-color: #8584ab;
}

.dataTables_wrapper.dataTables_wrapper {
	clear: none;
}
@media(max-width:880px) {
    .dataTables_wrapper.dataTables_wrapper {
    	clear: both;
    }
}
.dataTables_wrapper .dataTables_filter {
    margin:0;
}

.datatables-toolbox {
	text-align: right;
	white-space: nowrap;
	position: relative;
	z-index: 0;
}

@media(max-width:1279px){
    .datatables-toolbox {
        margin-right:16px;
    }
}

@media(max-width:766px){
    .datatables-toolbox {
        margin-right:10px;
    }
}

.datatables-toolbox > * {
	display: inline-block;
	vertical-align: middle;
}

.datatables-toolbox > .dataTables_filter {
	float: none;
	text-align: inherit;
}

.table-handler .table-title-row.table-title-row th {
	vertical-align: top;
	height: 22px;
	overflow: visible;
}

@media (max-width:480px) {
    .table-handler .table-title-row.table-title-row th {
        height:52px;
    }
}
.table-handler .table-title-row.table-title-row div {
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	position: absolute;
	white-space: nowrap;
	z-index: 1;
	background: #7b7a9a;
	font-weight: 200;
}

.table-handler .table-title-row.table-title-row .title {
	text-transform: uppercase;
}

.table-handler .table-title-row.table-title-row .more-less-product {
	right: 0;
	margin-right: 10px;
}

@media(max-width:480px) {
    .table-handler .table-title-row.table-title-row .more-less-product {
        margin-top:24px;
    }
}

span.more-less-button:before {
    content: '';
    display: inline-block;
    width: 6px;
    vertical-align: middle;
    height: 6px;
    margin-right: 8px;
    border-right: 1px solid #fff;
    -ms-transform: rotate(7deg);
    -webkit-transform: rotate(7deg);
    transform: rotate(-45deg);
    border-bottom: 1px solid #fff;
}

.table-handler .dataTables_scrollHeadInner .table-row-alternate-color::after,
.table-handler .dt-scroll-headInner .table-row-alternate-color::after {
	content: "";
	position: absolute;
	left: 0;
	width: 100%;
	background: #7b7a9a;
	display: block;
	height: 100%;
	z-index: -1;
}

.table-handler .dataTables_scrollHeadInner .table-row-alternate-color,
.table-handler .dt-scroll-headInner .table-row-alternate-color {
	background: #7b7a9a;
}

.table-handler .dataTables_scrollHeadInner .table-row-alternate-color th,
.table-handler .dt-scroll-headInner .table-row-alternate-color th {
    border-color:#8584ab;
}

.table-title-container {
	position: relative;
	z-index: 0;
	float: left;
}

@media (max-width:699px){
    .table-title-container {
        float:none;
    }
}

.table-title-container > .table-title {
	font-size: 28px;
	color: #232157;
	margin-left:16px;
}

@media (max-width: 699px) {
    .table-title-container > .table-title {
        margin-bottom: 16px;
        display: block;
    }
}

.datatables-toolbox {
    margin-bottom: 15px;
}

.table-title-container > .table-title-note {
	color: #232157;
	margin-left: 10px;
	display: none;
}

table.dataTable thead th {
	text-align: center;
	padding-left: 0;
	padding-right: 0;
	font-family: 'Hind', sans-serif;
	/*font-size: 16px;*/
	vertical-align: top;
}

table.dataTable thead th.dt-type-numeric,
table.dataTable thead th.dt-type-date{
    text-align: center;
}

table.dataTable td {
    color:rgba(35,33,87,0.85);
}

table.dataTable thead th,
table.dataTable tbody td {
	box-sizing: border-box;
	min-width: 60px;
	font-size:16px;
}

@media(max-width:1010px) {
    table.dataTable thead th,
    table.dataTable tbody td {
    	font-size:14px;
    }
}

@media(max-width:480px) {
    table.dataTable.dataTable thead th,
    table.dataTable tbody td {
        font-size:14px;
    }

}

table.dataTable tbody {
	font-family: "MyriadPro", Arial, sans-serif;
}

/* different border colors for cells */

table.dataTable tbody tr.odd td,
table.dataTable tbody tr:nth-child(odd) td {
    border-left:1px solid #d4d4e7;
}

table.dataTable tbody tr.even td,
table.dataTable tbody tr:nth-child(even) td {
    border-left:1px solid #c9cade;
}

/*table.dataTable tbody tr.odd td:first-child,
table.dataTable tbody tr.even td:first-child{
    border-left:0 none;
}*/

table.dataTable tbody tr td:first-child{
    border-left:0 none;
}

/* reset cells border-top from datatable.css */
table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
    border-top:0 none;
}

.table-handler .changed.changed-negative {
	color: #CC4533 !important;
}

.table-handler .changed.changed-positive {
	color: #50924D !important;
}

.table-handler .changed.changed-same {
	color: #828299 !important;
}

.table-handler .highlight.highlight-negative {
	background-color: #ff9688 !important;
}

.table-handler .highlight.highlight-positive {
	background-color: #b6e4b4 !important;
}

.table-handler .highlight.highlight-same {
	background-color: #c1c0e4 !important;
}

.table-handler td {
	-webkit-transition-property: background-color;
	transition-property: background-color;
	-webkit-transition-duration: 0.3s;
	        transition-duration: 0.3s;
}

.table-handler .highlight {
	-webkit-transition-duration: 0s;
	        transition-duration: 0s;
}

.dataTables_scrollHead,
.dt-scroll-head {
	background-color: #232157;
	color: #fff;
	font-size: 11px;
	font-weight: 400;
	z-index: 0;
	position: relative;
}

.dataTables_scrollHead th,
.dt-scroll-head th {
	/*border-left: 1px solid rgba(104, 103, 137, 0.5);*/
	border-left: 1px solid #4c4b80;
}

.dataTables_scrollHead thead tr:first-child th:first-child,
.dt-scroll-head thead tr:first-child th:first-child {
	border-left: transparent;
}

table.dataTable thead th, table.dataTable thead td {
	overflow: hidden;
	text-overflow: ellipsis;
	border-bottom: transparent;
	padding: 10px 5px 7px 5px;
}

table.dataTable>thead>tr>th,
table.dataTable>thead>tr>td{
    padding: 10px 5px 7px 5px;
}

table.dataTable>tbody>tr>th,
table.dataTable>tbody>tr>td{
    padding: 10px 4px 8px 4px;
}

table.dataTable.dataTable tbody td[colspan="4"] {
    background-color: #e8ebee;
}

table.dataTable tbody th, table.dataTable tbody td {
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 10px 4px 8px 4px;
	white-space:nowrap;
}
@media(max-width:480px) {
    #marketNowTables table.dataTable.dataTable tbody th,
    #marketNowTables table.dataTable.dataTable tbody td {
        font-size:14px;
    }
}

table.dataTable.stripe tbody tr.odd.odd,
table.dataTable.display tbody tr.odd.odd {
	background-color: #F3F3F6;
}

table.dataTable.stripe tbody tr.even.even,
table.dataTable.display tbody tr.even.even {
	background-color: #e1e4e8;
}

table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
	background-color: transparent;
}

table.dataTable.display tbody tr.even.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.even > .sorting_1 {
	background-color: transparent;
}

.dataTables_scrollFoot,
.dt-scroll-foot {
	background-color: #232157;
	color: #fff;
	font-size: 11px;
	font-weight: 400;
}

.TableFoot {
	display: table;
	width: 100%;
	font-size: 14px;
}

.TableFoot > * {
	display: table-row;
}

.TableFoot > * > *{
	display: table-cell;
	padding: 16px;
}

.TableFoot .data-delay{
	text-align: left;
	color: #8a8a8a;
}

.TableFoot .more-less-product{
	text-align: right;
	color: #8a8a8a;
}

.more-less-product{
	cursor: pointer;
}

.more-less-product:hover {
	text-decoration: underline;
}


/* TRAFFIC DATA SPEC */

.traffic-data .datatables-toolbox {
    margin:0;
}

.traffic-data table.dataTable tbody tr.odd td,
.traffic-data table.dataTable tbody tr.even td,
.traffic-data .dataTables_scrollHead th,
.traffic-data .dt-scroll-head th,
.traffic-data table.dataTable.dataTable thead th{
    border-left:0 none;
}

.traffic-data table.dataTable.dataTable thead th {
    text-align:right;
}

.traffic-data table.dataTable.dataTable thead th,
.traffic-data table.dataTable.dataTable thead td,
.traffic-data table.dataTable.dataTable tbody th,
.traffic-data table.dataTable.dataTable tbody td {
    padding-left:8px;
    padding-right:8px;
}

/*======================*/
.trafficnote {
	text-align: center;
	clear: both;
}

.footsummary .quantity {
	padding-left: 2px;
}

.trafficnote .trafficnote_left {
	float: left;
	font-size: 11px;
	padding-top: 5px;
	display: inline-block;
}

.trafficnote .trafficnote_center {
	float: none;
	font-size: 11px;
	padding-top: 5px;
	display: inline-block;
}

.trafficnote .trafficnote_right {
	float: right;
	font-size: 11px;
	padding-top: 5px;
	display: inline-block;
}

/* Certificates */

#certificate-search .sectionhead {
    margin-left:38px;
}

@media (max-width:780px) {

    #certificate-search .sectionhead {
        margin-left:0;
    }
}

.certificate-container .fieldset {
    margin-left: 10%;
    width: 720px;
}

@media (max-width:830px) {
    
    .certificate-container {
        font-size:16px;
    }
    
    .certificate-container .fieldset {
        margin-left:5%;
        width: auto;
    }
}

@media (max-width:766px) {
    .certificate-container .fieldset {
        margin-left:0;
    }
}

.certificate-container .fieldset > .field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom:18px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}

@media (max-width:430px) {
	.certificate-container .fieldset > .field {
		display:block;
	}

	.certificate-container .fieldset > .field > .label {
		width:100%!important;
		text-align:left!important;
	}
}

.certificate-container .fieldset > .label span {
    line-height: normal;
    display:inline-block;
}

.certificate-container .fieldset > .field > .label,
.certificate-container > .fieldset > .field > .value {}

.certificate-container .fieldset > .field > .label {
	line-height: 38px;
	width:42%;
	padding-right:8px;
}

.certificate-container .fieldset > .field > .label span {
	display:inline-block;
	line-height:normal
}

@media(max-width:766px) {
    .certificate-container .fieldset > .field > .label {
        display:inline-block;
        /* line-height:normal; */
        width:30%;
    }
}

.certificate-container .fieldset > .field > .value {
	width:57%;
}


.certificate-container .fieldset > .field > .value select {
    width:100%;
}

@media (max-width:766px) {
    .certificate-container .fieldset > .field > .value {
        width:70%;
    }
    
    .certificate-container .fieldset > .field > .value select {
        width:100%;
    }
    
}
@media (max-width: 430px) {
    .certificate-container .fieldset > .field > .value {
        width: 100%;
    }
}

.certificate-container .input-container {
    width:400px;
}

.certificate-container .fieldset > .field > .value > input[type="text"],
.certificate-container .fieldset > .field > .value > select {
	/*width: 50%;*/
	box-sizing: border-box;
	color:#232157;
	font-size:18px;
}

.certificate-container .sub-fieldset {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.certificate-container .sub-fieldset .sub-field {
	width:50%;
}

.certificate-container .sub-fieldset .sub-field:last-child {
	text-align: right;
}

.certificate-container .sub-fieldset .sub-field.second:last-child {
	text-align: left;
}

.certificate-container .sub-fieldset span {
    padding-left:4px;
    display:inline-block;
    line-height:1.3;
}

@media (max-width:480px) {
    .certificate-container .sub-fieldset {
        font-size:14px;
    }

	.certificate-container .sub-fieldset span {
		padding-left:0px;
	}
}

.certificate-container .sub-fieldset > .sub-field:first-child {
     margin-right:24px;
}

@media (max-width:780px) {
	.certificate-container .sub-fieldset > .sub-field:first-child {
		 margin-right:8px;
	}
}
.certificate-container .sub-fieldset input {
    height: 38px;
}

.certificate-container .sub-fieldset .normal {
    width: 70%;
    min-width: 120px;
}

@media (max-width:780px) {
.certificate-container .sub-fieldset .normal {
    width: 65%;
    min-width: 97px;
}
}
@media (min-width: 430px) and (max-width: 565px) {
    .certificate-container .hasDatepicker {
        background: none !important;
    }
}
@media (max-width: 398px) {
    .certificate-container .hasDatepicker {
        background: none !important;
    }
} 

.certificate-container .sub-fieldset .smaller {
    width: 37.5%;
}

.certificate-container hr {
    width: 90%;
    background-color:#e2e5e8;
    height:1px;
    margin:20px auto;
    border:0 none;
}

@media (max-width:780px) {
    .certificate-container hr {
        width:100%;
    }
}

.certificate-container .label.last {
    text-align: right;
}

.certificate-container .label.last span {
    margin-right: 20px;
}

@media (max-width:660px) {
	.certificate-container .label.last span {
		margin-right: 10px;
	}
}

.certificate-container .value.last .sub-fieldset {
    width: 100%;
}

.certificate-container .value.last .sub-field.first {
    width: 12.5%;
}
@media (max-width:660px) {
	.certificate-container .value.last .sub-field.first {
		width: 60px;
	}
}

.certificate-container .value.last .sub-field.second {
    width: 87.5%;
    line-height:38px;
    margin-left:12px;
}

@media(max-width:480px) {
    .certificate-container .value.last .sub-field.second {
        line-height:1.4;
        width: auto;
        padding: 0;
        margin-left:0;
    }
}
.certificate-container .value.last .sub-fieldset input {
    width: 75%;
}

.certificate-container .inputForms input {
    height: 20px;
    width: 150px;
    border: 1px solid #b4b4b4;
    padding: 12px 10px 8px;
    display: inline-block;
    color: #8a8a8a;
    font-size: 18px;
}

.certificate-container .hasDatepicker {
    background: url(../images/calendar-ico.svg) no-repeat right 10px center;
    background-size: 24px 24px;
}

.certificate-container .buttonTable {
    width: 100%;
}

.certificate-container .buttonTable .leftCell {
    margin-left:10%;
}

@media (max-width: 480px) {
    .certificate-container .buttonTable .leftCell {
        margin-left:0;
    }
}

.certificate-container .buttonTable {
    margin-bottom:42px;
}

.buttonTable .centerCell {
    display: table-cell;
    width: 33%;
    text-align: center;
}

.buttonTable .rightCell {
    display: table-cell;
    width: 33%;
}


/* ______________*/

.clearButton {
    padding: 10px 20px 10px 40px;
    font-size: 18px;
    border: 0 none;
    height: 40px;
    line-height: 24px;
    background: url(../images/menu-close.svg) no-repeat 10px 50%;
}
.clearButton:hover {
    background: url(../images/menu-close-w.svg) no-repeat 10px 50%;
}
.data-fields {
    text-align: left;
}
.data-field {
	display: inline-block;
	margin-right: 15px;
}

.data-field > * {
	display: table-row;
	font-size: 16px;;
}

.data-field > .data-name:after {
	content: ":";
}

table.dataTable.dataTable thead .sorting, table.dataTable.dataTable thead .sorting_asc, table.dataTable.dataTable thead .sorting_desc {
	background-image: none;
}

table.dataTable thead .sorting::after, table.dataTable thead .sorting_asc::after, table.dataTable thead .sorting_desc::after {
	content: "";
	display: block;
	height: 12px;
	background-repeat: no-repeat;
	background-position: center center;
}

table.dataTable thead .sorting::after {
	background-image: url("/assets/lib/DataTablesPortlet/sorting-default.png");
}

table.dataTable thead .sorting_asc::after {
	background-image: url("/assets/lib/DataTablesPortlet/sorting-asc.png");
}

table.dataTable thead .sorting_desc::after {
	background-image: url("/assets/lib/DataTablesPortlet/sorting-desc.png");
}

.dataTables_scrollBody table.dataTable thead th::after {
	content: none;
}


/* Market Now */
#marketNowMainPage {
	margin-top: 15px;
}

#marketNowMainPage .datatables-toolbox {
    display:none;
}

#marketNowMainPage.ui-tabs ul.ui-tabs-nav {
	margin-bottom: 10px;
}

#marketNowMainPage.ui-tabs .ui-tabs-nav li.ui-tabs-active {
	background: #fff;
	border-left: 0;
}

#marketNowMainPage.ui-tabs .ui-tabs-nav li.ui-tabs-active + li {
	border-left: 0;
}

#marketNowMainPage.ui-tabs .ui-tabs-nav li a {
	height: auto;
	padding: 14px 8px 8px;
}

@media (max-width: 1120px) {
    #marketNowMainPage.ui-tabs .ui-tabs-nav li a {
        font-size:16px;
        padding: 12px 8px 7px 8px;
    }
}

@media (max-width: 980px) {
    #marketNowMainPage.ui-tabs .ui-tabs-nav li a {
        font-size:14px;
        padding: 12px 8px 7px 8px;
    }
    
    #marketNowMainPage table.dataTable.dataTable thead th {
        font-size:14px;
    }
}

@media (max-width: 766px) {
    #marketNowMainPage.ui-tabs .ui-tabs-nav li a {
        font-size:16px;
    }

    #marketNowMainPage table.dataTable.dataTable thead th,
    #marketNowMainPage table.dataTable.dataTable thead td {
        font-size:16px;
    }
}

#marketNowMainPage.ui-tabs .ui-tabs-nav li.ui-tabs-active a {
	color: #232157;
}

#marketNowMainPage.ui-tabs .ui-tabs-nav li.ui-tabs-active:before {
	border-top: 4px solid #232157;
	width: 100%;
	position: absolute;
	top: -4px;
	content: "";
	left: 0;
}

#marketNowMainPage .dataTables_scrollHead,
#marketNowMainPage .dt-scroll-head {
	background-color: #fff;
	color: #232157;
}

#marketNowMainPage table.dataTable.dataTable thead th {
	vertical-align: bottom;
	padding-top:0;
	border-left: 0;
	text-align:right;
	color:#232157;
}

#marketNowMainPage table.dataTable.dataTable thead th:first-child {
    padding-left:16px;
}

#marketNowMainPage table.dataTable.dataTable thead th:last-child {
    padding-right:16px;
}

@media (max-width:480px) {
    
    #marketNowMainPage table.dataTable.dataTable thead th {
        font-size:14px;
    }
    
    #marketNowMainPage table.dataTable.dataTable thead th:first-child {
        padding-left:8px;
    }
    
    #marketNowMainPage table.dataTable.dataTable thead th:last-child {
        padding-right:8px;
    }
}


#marketNowMainPage table.dataTable.dataTable thead th[data-field-id='#seccode#'] {
    text-align:left;
}

#marketNowMainPage table.dataTable thead th::after {
	height: 0!important;
}

#marketNowMainPage table.dataTable.stripe tbody tr.odd.odd,
#marketNowMainPage table.dataTable.display tbody tr.odd.odd,
#marketNowMainPage table.dataTable.stripe tbody tr:nth-child(odd),
#marketNowMainPage table.dataTable.display tbody tr:nth-child(odd) {
	background-color: #fff;
}

#marketNowMainPage table.dataTable.stripe tbody tr.even.even,
#marketNowMainPage table.dataTable.display tbody tr.even.even,
#marketNowMainPage table.dataTable.stripe tbody tr:nth-child(even),
#marketNowMainPage table.dataTable.display tbody tr:nth-child(even) {
	background-color: #fff;
}

#marketNowMainPage table.dataTable.stripe>tbody>tr:nth-child(odd)>*,
#marketNowMainPage table.dataTable.display>tbody>tr:nth-child(odd)>*,
#marketNowMainPage table.dataTable.display tbody tr:hover>.sorting_1,
#marketNowMainPage table.dataTable.order-column.hover tbody tr:hover>.sorting_1,
#marketNowMainPage table.dataTable.display tbody tr:hover>.sorting_2,
#marketNowMainPage table.dataTable.order-column.hover tbody tr:hover>.sorting_2,
#marketNowMainPage table.dataTable.display tbody tr:hover>.sorting_3,
#marketNowMainPage table.dataTable.order-column.hover tbody tr:hover>.sorting_3,
#marketNowMainPage table.dataTable.display>tbody>tr:nth-child(odd)>.sorting_1,
#marketNowMainPage table.dataTable.order-column.stripe>tbody>tr:nth-child(odd)>.sorting_1,
#marketNowMainPage table.dataTable.stripe>tbody>tr:nth-child(odd)>*,
#marketNowMainPage table.dataTable.display>tbody>tr:nth-child(odd)>* {
    box-shadow: none;
}

table.dataTable.order-column>tbody tr>.sorting_1,
table.dataTable.order-column>tbody tr>.sorting_2,
table.dataTable.order-column>tbody tr>.sorting_3,
table.dataTable.display>tbody tr>.sorting_1,
table.dataTable.display>tbody tr>.sorting_2,
table.dataTable.display>tbody tr>.sorting_3,
table.dataTable.display>tbody>tr:nth-child(odd)>.sorting_2,
table.dataTable.order-column.stripe>tbody>tr:nth-child(odd)>.sorting_2,
table.dataTable.display>tbody>tr:nth-child(odd)>.sorting_3,
table.dataTable.order-column.stripe>tbody>tr:nth-child(odd)>.sorting_3,
table.dataTable.hover>tbody>tr:hover>*,
table.dataTable.display>tbody>tr:hover>*,
table.dataTable.display>tbody>tr:nth-child(odd)>.sorting_1,
table.dataTable.order-column.stripe>tbody>tr:nth-child(odd)>.sorting_1,
table.dataTable.display tbody tr:hover>.sorting_1,
table.dataTable.order-column.hover tbody tr:hover>.sorting_1{
    box-shadow: none;
}

#marketNowMainPage table.dataTable tbody tr td {
	border: 0;
	padding:7px 5px;
}

#marketNowMainPage table.dataTable tbody tr td:first-child {
    padding-left:16px;
    font-size:18px;
}

#marketNowMainPage table.dataTable tbody tr td:last-child {
	padding-right:16px;
}

@media(max-width:1010px) {
    #marketNowMainPage table.dataTable tbody tr td:first-child {
        font-size:15px;
        padding-left:10px;
    }
    
    #marketNowMainPage table.dataTable tbody tr td:last-child {
    	padding-right:10px;
    }
}

@media(max-width:920px) {
    
    #marketNowMainPage table.dataTable tbody tr td {
        padding:5px;
        font-size:12px;
    }

    #marketNowMainPage table.dataTable.dataTable thead th,
    #marketNowMainPage table.dataTable tbody tr th {
        font-size:12px;
    }
}

@media(max-width:766px) {
    
    #marketNowMainPage table.dataTable.dataTable thead th,
    #marketNowMainPage table.dataTable tbody tr th,
    #marketNowMainPage table.dataTable tbody tr td {
        font-size:14px;
    }
    
    #marketNowMainPage table.dataTable tbody tr td:first-child {
        padding-left:8px;
    }
}

#marketNowMainPage .dataTables_scrollBody,
#marketNowMainPage .dt-scroll-body{
	border-top: 1px solid #7b7a9a;
	border-bottom: 1px solid #7b7a9a;
	color:#232157;
	padding:5px 0;
}

#marketNowMainPage .dataTables_scrollFoot,
#marketNowMainPage .dt-scroll-foot {
	display: none;
}

#marketNowMainPage table.dataTable thead th:not(.sorting_disabled) {
	cursor: default;
}

#marketNowMainPage .TableFoot .data-delay,
#marketNowMainPage .dt-scroll-foot .data-delay {
	text-align: right;
	padding:8px 16px;
}

table.dataTable thead>tr>th.dt-orderable-asc span.dt-column-order,
table.dataTable thead>tr>th.dt-orderable-desc span.dt-column-order,
table.dataTable thead>tr>th.dt-ordering-asc span.dt-column-order,
table.dataTable thead>tr>th.dt-ordering-desc span.dt-column-order,
table.dataTable thead>tr>td.dt-orderable-asc span.dt-column-order,
table.dataTable thead>tr>td.dt-orderable-desc span.dt-column-order,
table.dataTable thead>tr>td.dt-ordering-asc span.dt-column-order,
table.dataTable thead>tr>td.dt-ordering-desc span.dt-column-order{
    /*right: 2px;*/
    font-size: 16px;
    inset: 0;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}


table.dataTable thead>tr>th.dt-orderable-asc span.dt-column-order:before,
table.dataTable thead>tr>th.dt-orderable-asc span.dt-column-order:after,
table.dataTable thead>tr>th.dt-orderable-desc span.dt-column-order:before,
table.dataTable thead>tr>th.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead>tr>th.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead>tr>th.dt-ordering-asc span.dt-column-order:after,
table.dataTable thead>tr>th.dt-ordering-desc span.dt-column-order:before,
table.dataTable thead>tr>th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead>tr>td.dt-orderable-asc span.dt-column-order:before,
table.dataTable thead>tr>td.dt-orderable-asc span.dt-column-order:after,
table.dataTable thead>tr>td.dt-orderable-desc span.dt-column-order:before,
table.dataTable thead>tr>td.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead>tr>td.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead>tr>td.dt-ordering-asc span.dt-column-order:after,
table.dataTable thead>tr>td.dt-ordering-desc span.dt-column-order:before,
table.dataTable thead>tr>td.dt-ordering-desc span.dt-column-order:after{
    line-height: 11px;
    opacity: 0.2;
    position: relative;
}

table.dataTable thead>tr>th.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead>tr>th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead>tr>td.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead>tr>td.dt-ordering-desc span.dt-column-order:after{
    opacity: 0.8;
}

table.dataTable thead>tr>th.dt-orderable-asc,
table.dataTable thead>tr>th.dt-orderable-desc,
table.dataTable thead>tr>th.dt-ordering-asc,
table.dataTable thead>tr>th.dt-ordering-desc,
table.dataTable thead>tr>td.dt-orderable-asc,
table.dataTable thead>tr>td.dt-orderable-desc,
table.dataTable thead>tr>td.dt-ordering-asc,
table.dataTable thead>tr>td.dt-ordering-desc{
    /*padding-right: 20px;*/
    padding-right: 5px;
    position: unset;
}

table.dataTable thead>tr>th.dt-orderable-asc:hover,
table.dataTable thead>tr>th.dt-orderable-desc:hover,
table.dataTable thead>tr>td.dt-orderable-asc:hover,
table.dataTable thead>tr>td.dt-orderable-desc:hover{
    outline: 0 none;
}

table.dataTable.stripe>tbody>tr:nth-child(odd)>*,
table.dataTable.display>tbody>tr:nth-child(odd)>*{
    box-shadow: none;
}

table.dataTable.stripe tbody tr:nth-child(odd),
table.dataTable.display tbody tr:nth-child(odd) {
    background-color: #F3F3F6;
}

table.dataTable.stripe tbody tr:nth-child(even),
table.dataTable.display tbody tr:nth-child(even) {
    background-color: #e1e4e8;
}

/* Product Search */
@media (max-width: 766px) {
    #productSearch {
        margin: 0px;
    }
}

#productSearch .ui-tabs ul.ui-tabs-nav {
    margin-bottom: 0;
}

#productSearch > .datatables-toolbox {
    margin-bottom: 36px;    
    width:100%;
    text-align:left;
}

#productSearch > .datatables-toolbox #searchForm {
    float:left;
}

@media(max-width:766px) {
    #productSearch > .datatables-toolbox #searchForm {
        float: none;
        width: 100%;
    }
}

#productSearch > .datatables-toolbox #productDownloadContainer {}
#productSearch > .datatables-toolbox #productDownloadContainer #productDownloadBt {
    float: right;
}

@media(max-width:766px) {
    #productSearch > .datatables-toolbox #productDownloadContainer #productDownloadBt {
        float: none;
        margin-top: 20px;
    }
}

#productSearch > .datatables-toolbox * {
    display:block;
}

#productSearch > .datatables-toolbox #productDownloadContainer #productDownloadBt:before {
    margin-right: 10px;
}
    
.btn.icon-download {
    background-image: url(../images/datadwl-icon-w.svg);
    background-repeat:no-repeat;
    background-position:15px 8px;
    padding-left:45px !important;
    padding-right:25px !important;
}

.icon-download:before {
    display:none;
}

#productSearch ul {
    clear:both;
    table-layout:fixed;
    width:auto;
}

#productSearch ul li > div {
    padding:8px;
}

#productSearch ul li > div > * {
   display: inline-block;
}

#productSearch .table-handler .datatables-toolbox {
    margin:0;
}

#productSearch .ui-tabs .ui-tabs-nav.ui-tabs-nav li a {
  height:auto;
  padding: 0;
}

@media(max-width:480px) {
    #productSearch .ui-tabs .ui-tabs-nav.ui-tabs-nav li {
        font-size:14px;
    }
}

#productSearch .ui-tabs .ui-tabs-nav li .count {
    padding-left: 5px;
}
#productSearch .ui-tabs .ui-tabs-nav li.ui-tabs-active .count {
    color: #fff;
}


/* _____________________ */

td.details-toggle {
	position: relative;
	padding-left: 30px;
	cursor: pointer;
}

td.details-toggle-open:before {
    height: 36px;
    width: 36px;
    display: block;
    font-size: 28px;
    margin-right: 10px;
	box-sizing: content-box;
	text-align: center;
	font-family: 'Courier New', Courier, monospace;
	content: '+';
}

td.details-toggle-close:before {
    height: 36px;
    width: 36px;
    display: block;
    font-size: 28px;
    margin-right: 10px;
	box-sizing: content-box;
	text-align: center;
	font-family: 'Courier New', Courier, monospace;
	content: '-';
}


/* Temp Detailed List */

.tempDetailedList {
    display: table;
    margin-left: 35px;
    width: 100%;
    color: rgba(35,33,87,0.85);
    box-sizing: border-box;
}
@media (max-width: 766px) {
    .tempDetailedList {
        margin-left: 0;
    }
}

.tempDetailedList > div {
    display:table-row;
}

.tempDetailedList > div > * {
    display:table-cell;
}

@media(max-width:766px) {
    .tempDetailedList > div {
        display:block;
        margin-bottom:16px;
    }
    
    .tempDetailedList > div > * {
        display:block;
    }
}

.tempDetailedList .caption {
    padding:3px 10px 3px 0;
    width:25%;
}
@media(max-width: 766px) {
    .tempDetailedList .caption {
        width: auto;
    }
}
.tempDetailedList .value p {
    margin:3px 0;
}

.tempDetailedList .caption:after {
    content:":";
}


/* Main Lists */

#issuermainList table th,
#issuermainList table td {
    border:0 none;
    font-size:18px;
    text-align:left;
    vertical-align: middle;
    padding-left:16px;
}


    #issuermainList .table-title-container {
        float:none;
    }

#vendormainList table th,
#vendormainList table td {
    border:0 none;
    font-size:18px;
    padding:12px 16px 8px;
    color:rgba(35,33,87,0.85);
    vertical-align: middle;
}


@media (max-width:1010px) {
    #issuermainList table th,
    #issuermainList table td,
    #vendormainList table th,
    #vendormainList table td {
        font-size:16px;
    }
}

@media (max-width:768px) {
    #issuermainList table th,
    #issuermainList table td,
    #vendormainList table th,
    #vendormainList table td {
        font-size:13px;
    }
}

#vendormainList table td.details-toggle {
    padding-right:0;
    padding-left:0;
}

#vendormainList table .select-td {
    text-align:left;
}

#vendormainList table th.select-td select {
    min-width:300px;
}

#vendormainList .dataTable tbody tr > td[colspan="3"] {
    background-color: #e8ebee;
}

/* kosarak összetétele táblázat */
@media (max-width:768px) {

    #buskets .ui-tabs-nav.ui-tabs-nav li a {
        font-size:16px;
    }
    
    #buskets .data-fields { font-size:14px!important; }

}

#buskets .data-fields { border-bottom: 1px solid rgba(153,153,204,0.35); }

#buskets table.dataTable td {
    text-align:right;
}

#buskets table.dataTable td:first-child {
    text-align:left;
}

.HeadingStructurePortlet .headingContentListTable{
    width:100%;
    display:table;
}

.HeadingStructurePortlet .headingContentListTable > div{
    display:table-row;
    height: 37px;
}

.HeadingStructurePortlet .headingContentListTable > div:nth-child(odd){
    background-color: #F3F3F6;
}


.HeadingStructurePortlet .headingContentListTable > div:nth-child(even){
    background-color: #E2E5E8;
}

.HeadingStructurePortlet .headingContentListTable > div > div{
    display:table-cell;
    vertical-align: middle;
    padding: 5px;
}

.HeadingStructurePortlet .headingSelectorContainer{
    margin-bottom: 10px;
}

.HeadingStructurePortlet .headingSelectorContainer select {
    width: 120px;
    height: 35px;
}

.HeadingStructurePortlet .headingSelectorContainer label {
    font-weight:normal;
    margin-right:8px;
    margin-left:16px;
}

@media(max-width:768px) {
    .HeadingStructurePortlet .headingSelectorContainer label {
        margin-left:0;
    }
}


/* Favourites */
.btn-favourite {
    height: 18px;
    width: 18px;
    display: inline-block;
    cursor: pointer;
    margin-right: 4px;
    vertical-align: text-bottom;
    margin-left: 0px;
}

.btn-favourite.btn-favourite-remove {
    background: transparent url(../images/del-fav-hover.svg) no-repeat center;
}

    .btn-favourite.btn-favourite-remove:hover {
        background: transparent url(../images/del-fav-hover.svg) no-repeat center;
    }

.btn-favourite.btn-favourite-add {
    background: transparent url(../images/add-fav.svg) no-repeat center;
}

    .btn-favourite.btn-favourite-add:hover {
        background: transparent url(../images/add-fav-hover.svg) no-repeat center;
    }

.btn-favourite.btn-favourite-loading {
    background: transparent url(../images/favorite-loader.gif) no-repeat center;
}

.accordion-panel {
    margin-bottom: 30px;
}

.accordion-panel > .accordion-group:last-child {
    border-bottom: 1px solid #e2e5e8;
}

.accordion-panel > .accordion-group > .accordion-header {
    overflow: hidden;
    height: 51px;
    font-size: 24px;
    color: #232157;
    cursor: pointer;
    line-height: 51px;
    border-top: 1px solid #e2e5e8;
}

.accordion-panel > .accordion-group > .accordion-header > .accordionArrowParent {
    margin-top: 7px !important;
}

.accordion-panel > .accordion-group > .accordion-header > .accordionArrow {
    padding-top: 10px !important;
}

.accordion-panel > .accordion-group.open > .accordion-header > .accordionArrowParent > .accordionArrow {
    transform: none;
}

.accordion-panel > .accordion-group.close > .accordion-header > .accordionArrowParent > .accordionArrow {
    transform: rotate(180deg);
}

.accordion-panel > .accordion-group > .accordion-header > .accordion-header-text {
    padding-left: 25px;
}

.accordion-panel > .accordion-group.close > .accordion-header:hover {
    background: rgba(123,122,154,0.1);
}

.accordion-panel > .accordion-group > .accordion-body {
    overflow: hidden;
    height: 0;
    transition: 0.3s cubic-bezier(0.3, 0, 0.3, 1) height;
    /*margin-left: 0;*/
}

.accordion-panel > .accordion-group.open > .accordion-body {
    height: auto;
}

.accordion-panel > .accordion-group.noAnim > .accordion-body {
    -webkit-transition:none;
    transition: none;
}

.accordion-panel .accordion-group.open > .accordion-header:before {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.accordion-panel .accordion-header:before {
    width: 15px;
    height: 6px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 0.3s ease-in-out transform;
    transition: 0.3s ease-in-out transform;
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    margin-top: -5px;
    margin-right: 12px;
    background: url('../images/arrow-icon.svg') no-repeat left top;
}


.accordion-body .table-title-container > .table-title {
    font-size:24px;
    margin-left:16px;
    margin-bottom:8px;
    display:block;
}

.hidden {
    display:none;
}


#favourites .no-favourite-container {
    margin: 0 16px 16px;
    display: block;
}

#favourites .dummy-table thead > .table-row-alternate-color {
    display:none;
}

#favourites .dummy-table .dataTables_scrollBody {
    display:none;
}

#favourites .dummy-table .dataTables_scrollFoot {
    display:none;
}

#favourites .datatables-toolbox {
    margin-bottom: 0;
}

#favourites .accordion-panel .accordion-header:before {
    margin-left:16px;
}

#favourites .accordion-panel > .accordion-group .table-title-container {
    margin-top:24px;
}

#favourites .bux {
    margin: 36px 16px;
    max-width: 450px;
}
#favourites .bux span {
    font-size: 22px;
    line-height: 39px;
    white-space: unset;    
}

.fancybox-nav.fancybox-nav {
    width: 20%;
}


/*Adatletöltési hiba üzenet - ideiglenes - 2020. 04.03.*/
.StaticContentPortlet.letoltesek-hiba-container {
    border: 1px solid #232157;
    max-width: 1050px;
    margin: 30px auto;
    padding: 15px;
    box-sizing: border-box;
    font-size: 16px;
    font-style: italic;
}

.StaticContentPortlet.letoltesek-hiba-container .letoltesek-hiba-title {
    font-size: larger;
    margin-bottom: 20px;
}

.green-platform-leaf {
    background: transparent url('../images/green-platform.svg') no-repeat 98% 50%;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.leaf-td {
    text-align: center !important;
}